<template>
  <el-main>
    <el-form
      ref="form"
      :model="addForm"
      :rules="rules"
      label-width="200px"
      class="addForm"
    >
      <el-form-item label="名称：" prop="name">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="addForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="性别：">
        <el-radio-group v-model="addForm.sex">
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="硬件设备：" prop="shipper_id">
        <el-select
          v-model="addForm.shipper_id"
          filterable
          placeholder="请选择设备"
          style="width: 100%"
        >
          <el-option
            v-for="item in shipperList"
            :key="item.id"
            :label="item.equipment_no"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省市区：" class="city">
        <div class="box">
          <el-select
            v-model="addForm.province"
            placeholder="请选择"
            @change="(val) => sonArea(0, val,1)"
          >
            <el-option
              v-for="(item, index) in area"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.city"
            placeholder="请选择"
            @change="(val) => sonArea(1, val,1)"
          >
            <el-option
              v-for="(item, index) in cityArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.county"
            placeholder="请选择"
            @change="(val) => sonArea(2, val,1)"
          >
            <el-option
              v-for="(item, index) in areaArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="现住址：" prop="address">
        <el-input v-model="addForm.address"></el-input>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input v-model.number="addForm.age"></el-input>
      </el-form-item>
      <el-form-item label="电话：" prop="mobile">
        <el-input v-model.number="addForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="生日：">
        <el-input v-model="addForm.birthday"></el-input>
      </el-form-item>
      <el-form-item label="房间号：" prop="room_num">
        <el-input v-model="addForm.room_num"></el-input>
      </el-form-item>
      <el-form-item label="隔离点：" prop="government_id">
        <el-select
          v-model="addForm.government_id"
          filterable
          placeholder="请选择隔离点"
          @change="getGovernment"
        >
          <el-option
            v-for="item in governmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="起始观察期：" prop="period_observation">
        <el-date-picker
          v-model="period_observation"
          @change="getTime"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="当前温度：" prop="current_temperature">
        <el-input v-model="addForm.current_temperature"></el-input>
      </el-form-item>
      <el-form-item label="最高温度：" prop="maximum_temperature">
        <el-input v-model="addForm.maximum_temperature"></el-input>
      </el-form-item>
      <el-form-item label="在线状态：">
        <el-radio-group v-model="addForm.online_status">
          <el-radio :label="1">在线</el-radio>
          <el-radio :label="0">离线</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="隔离状态：">
        <el-radio-group v-model="addForm.status">
          <el-radio :label="1">隔离中</el-radio>
          <el-radio :label="0">解除隔离</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="工作人员：" prop="teacher_id">
        <el-select
          v-model="addForm.teacher_id"
          filterable
          placeholder="请选择工作人员"
          style="width: 100%"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.teacher"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="saveForm">保存</el-button>
        <router-link to="/home/LsolationPersonnel" style="margin-left: 12px">
          <el-button>返回</el-button>
        </router-link>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      area: [],
      cityArr: [],
      areaArr: [],
      showAdd: !1,
      teacherList: [],
      shipperList: [],
      governmentList: [],
      period_observation: "",
      addForm: {
        name: "",
        nickname: "",
        sex: 0,
        shipper_id: "",
        province: "",
        city: "",
        county: "",
        age: "",
        mobile: "",
        birthday: "",
        address: "",
        room_num: "",
        government_id: "",
        isolation_point: "",
        period_observation: "",
        current_temperature: "",
        maximum_temperature: "",
        online_status: 1,
        status: 1,
        teacher_id: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        shipper_id: [
          { required: true, message: "请选择硬件设备", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入现住址", trigger: "blur" }],
        age: [
          { required: true, message: "请输入年龄", trigger: "blur" },
          { type: "number", message: "年龄必须为数字值", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入电话", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号码",
            trigger: "blur",
          },
        ],
        room_num: [
          { required: true, message: "请输入房间号", trigger: "blur" },
        ],
        government_id: [
          { required: true, message: "请选择隔离点", trigger: "blur" },
        ],
        period_observation: [
          { required: true, message: "请选择起始观察期", trigger: "blur" },
        ],
        current_temperature: [
          { required: true, message: "请输入当前温度", trigger: "blur" },
        ],
        maximum_temperature: [
          { required: true, message: "请输入最高温度", trigger: "blur" },
        ],
        teacher_id: [
          { required: true, message: "请选择工作人员", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    let info = this.$route.query.info;
    if (info) {
      info = JSON.parse(info);
      this.handleInfo(info);
    }
    this.getArea();
    this.getTeacherList();
    this.getShipperList();
    this.getGovernmentList();
  },
  methods: {
    handleInfo(info) {
      for (let i in info) {
        for (let y in this.addForm) {
          if (y == i) {
            this.addForm[y] = info[i];
          }
        }
      }
      this.addForm.mobile = Number(this.addForm.mobile);
      this.period_observation = new Date(info.period_observation * 1000);
      this.addForm.province = Number(this.addForm.province);
      this.addForm.city =Number(this.addForm.city);
      this.addForm.county= Number(this.addForm.county);
      this.addForm.id = info.id;
    },
    // 获取省市区信息
    getArea() {
      let that = this;
      this.$axios
        .get(this.$api.area, {
          tree: 1,
          level: 2,
        })
        .then((res) => {
          if (res.code == 0) {
            that.area = res.result.list;
            if (this.addForm.id) {
              this.sonArea(0, this.addForm.province);
              this.sonArea(1, this.addForm.city);
              this.sonArea(2, this.addForm.county);
            }
          }
        });
    },
    getGovernment(val) {
      let item = this.governmentList.find((item) => item.id == val);
      this.addForm.isolation_point = item.name;
    },
    //获取隔离点
    getGovernmentList() {
      this.$axios
        .post(this.$api.government.list, { rows: 10000 })
        .then((res) => {
          if (res.code == 0) {
            this.governmentList = res.result.list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //添加隔离人员
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.addForm.id
            ? this.$api.student.edit
            : this.$api.student.add;
          this.$axios.post(url, this.addForm).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.addForm.id ? "编辑成功" : "添加成功");
              this.$router.push("/home/LsolationPersonnel");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getTime(val) {
      if (val) this.addForm.period_observation = val.getTime() / 1000;
    },
    // 三级联动选择地区
    sonArea(status, val,a) {
      if (status == 0) {
        let index = this.area.find((item) => item.id == val);
        this.cityArr = index._child;
        if(a) this.addForm.city = '';
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.id == val);
        this.areaArr = index._child;
        if(a) this.addForm.county = '';
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.id == val);
      }
    },
    //获取医护人员
    getTeacherList() {
      this.$axios.post(this.$api.teacher.list, { rows: 1000 }).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.teacherList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取设备
    getShipperList() {
      this.$axios.post(this.$api.shipper.list, { rows: 10000 }).then((res) => {
        if (res.code == 0) {
          this.shipperList = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-form-item {
    width: 750px;
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
